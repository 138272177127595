import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../components/Header";
import Nav from "../../components/Nav";

export default function Partner() {
  let { locationId } = useParams();
  let { dataSource } = useParams();
  let spreadsheet = "";
  const navigator = useNavigate();
  const [email, setEmail] = useState("");
  const [storeNumber, setStoreNumber] = useState(locationId);
  const [choices, setChoices] = useState([]);
  const [data, setData] = useState();
  const [otherText, setOtherText] = useState("");

  //Are we pulling the store data or district/region data?
  if (dataSource === "store") {
    spreadsheet = "data_lowes_2024.xlsx";
  } else {
    spreadsheet = "data_lowes_district_2024.xlsx";
  }

  useEffect(() => {
    const getData = async () => {
      fetch(spreadsheet)
        .then((response) => response.blob())
        .then((blob) => readXlsxFile(blob))
        .then((rows) => {
          const filteredRow = rows.filter((row) => {
            return parseInt(row[5]) === parseInt(locationId);
          });
          setData(filteredRow[0]);
        });
    };
    getData();
  }, [locationId, spreadsheet]);

  const onSubmit = () => {
    const formData = localStorage.getItem("forms");
    let array = JSON.parse(formData) || [];
    const form = {
      order: formData ? array?.length : 0,
      title: "Lowes Show",
      time: Date.now(),
      store: storeNumber,
      email: email,
      choices: choices,
      name: data ? data[42] : "",
      phone: data ? data[43] : "",
      form: "partner",
      other: choices.includes("Other") ? otherText : "",
    };
    array.push(form);
    localStorage.setItem("forms", JSON.stringify(array));
    toast.success("Email Submitted Successfully!");

    console.log(data);
  };

  const handleCheckbox = (value) => {
    if (choices.includes(value)) {
      setChoices(choices.filter((item) => item !== value));
    } else {
      setChoices([...choices, value]);
    }
  };

  return (
    <div className="h-screen flex flex-col bg-white  w-full">
      <Nav title="Your RSR Partner" color="white" />
      <div className="flex justify-between items-center h-full px-20 gap-20 relative">
        <img
          src="png/arrow_prev.png"
          alt="prev"
          className="absolute rsr__prev-arrow cursor-pointer"
          onClick={() => navigator(-1)}
        />
        <div className="flex flex-col w-1/2">
          <h1 className="text-5xl mb-14">
            Your GAF Retail Service Representative
          </h1>
          {data && (
            <div className="flex flex-col items-start gap-4">
              <h2 className="flex flex-col text-7xl font-semibold">
                {data[42]}
              </h2>
              <p className="text-2xl mb-14">{data[43]}</p>
            </div>
          )}
          <h2 className="text-2xl mt-6">
            Your GAF RSR is your local business partner dedicated to working
            with your team inside and outside of the store to identify market
            opportunities, prospect for new contractors, strengthen existing
            contractor relationships, build loyalty, and collectively grow the
            business.
          </h2>
        </div>
        <div className="flex-col w-1/2 gap-6">
          <div className="partner-form bg-[#F6F6F6] py-8 px-12">
            <p className="text-2xl font-medium mb-9 font-semibold">
              Enter your email address to have your RSR contact you about your
              2025 roofing business plan.
            </p>
            <div className="w-1/2 inline-block pr-2">
              <label>EMAIL ADDRESS</label>
              <input
                className="py-2 border mb-16 w-full text-2xl py-4 px-4"
                placeholder="Enter Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="w-1/2 inline-block pl-2">
              <label>STORE NUMBER</label>
              <input
                className="py-2 border mb-16 w-full text-2xl py-4 px-4"
                placeholder="Enter Store Number"
                value={storeNumber}
                onChange={(e) => setStoreNumber(e.target.value)}
              />
            </div>
            <p className="text-2xl font-medium mb-3 font-semibold">
              What would you like more information about?
            </p>
            <input
              className="mr-2"
              type="checkbox"
              id="attachmentRate"
              name="attachmentRate"
              value="Attachment Rate"
              checked={choices.includes("Attachment Rate")}
              onChange={() => handleCheckbox("Attachment Rate")}
            />
            <label htmlFor="attachmentRate" className="text-2xl">
              Attachment Rate
            </label>
            <br />
            <input
              className="mr-2"
              type="checkbox"
              id="productTraining"
              name="productTraining"
              value="Product Training"
              checked={choices.includes("Product Training")}
              onChange={() => handleCheckbox("Product Training")}
            />
            <label htmlFor="productTraining" className="text-2xl">
              Product Training
            </label>
            <br />
            <input
              className="mr-2"
              type="checkbox"
              id="insurance"
              name="insurance"
              value="Insurance"
              checked={choices.includes("Insurance")}
              onChange={() => handleCheckbox("Insurance")}
            />
            <label htmlFor="insurance" className="text-2xl">
              Insurance
            </label>
            <br />
            <input
              className="mr-2"
              type="checkbox"
              id="hispanicRoofers"
              name="hispanicRoofers"
              value="Hispanic Roofers"
              checked={choices.includes("Hispanic Roofers")}
              onChange={() => handleCheckbox("Hispanic Roofers")}
            />
            <label htmlFor="hispanicRoofers" className="text-2xl">
              Hispanic Roofers
            </label>
            <br />
            <input
              className="mr-2"
              type="checkbox"
              id="PFC"
              name="PFC"
              value="PFC"
              checked={choices.includes("PFC")}
              onChange={() => handleCheckbox("PFC")}
            />
            <label htmlFor="PFC" className="text-2xl">
              PFC
            </label>
            <br />
            <input
              className="mr-2"
              type="checkbox"
              id="Other"
              name="Other"
              value="Other"
              checked={choices.includes("Other")}
              onChange={() => handleCheckbox("Other")}
            />
            <label htmlFor="Other" className="text-2xl">
              Other
            </label>
            <br />
            {
              choices.includes("Other") && (
                <input
                  className="py-2 border mb-16 w-full text-2xl py-4 px-4"
                  placeholder="Enter Other"
                  value={otherText}
                  onChange={(e) => setOtherText(e.target.value)}
                />
              )
            }
            <br />
            <button
              className="bg-red-500 py-2 px-20 mt-3 text-white text-2xl w-full"
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-left" autoClose={10000} />
    </div>
  );
}
