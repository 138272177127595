import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import Nav from "../../components/Nav";
import Testimonials from "../../components/Testimonials";

export default function SampleReports() {
  return (<>
    <Nav title={`Sample Reports`} color="white" />
    <div className="sample-reports">
      <Link to="/sample-reports/quickmeasure" className="sample-reports__link btn btn--square btn--red">Single-family QuickMeasure Report</Link>
      <Link to="/sample-reports/multifamily-quickmeasure" className="sample-reports__link btn btn--square btn--red">Multi-family QuickMeasure Report</Link>
      <Link to="/sample-reports/scopeconnect" className="sample-reports__link btn btn--square btn--red">ScopeConnect Report</Link>
      <Link to="/sample-reports/takeoff" className="sample-reports__link btn btn--square btn--red">Takeoff Report</Link>
    </div>
  </>)
}
