import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import Nav from "../../components/Nav";
import Testimonials from "../../components/Testimonials";

export default function Insurance() {
  const [testimonialsOpen, setTestimonialsOpen] = useState(false);

  return (<>
    <Nav title={`Insurance`} color="blue" />
    <div className="hispanic-roofers insurance">
      <div className="hispanic-roofers__left">
        <img
          src={`img/insurance.jpg`}
          alt="Insurance images"
        />
      </div>
      <div className="hispanic-roofers__right">
          <img
            src={`img/scope-connect.jpg`}
            alt="Scope Connect logo"
            className="insurance__img"
          />
          <p className="insurance__text">Contractors who are engaged or are new to Insurance Restoration Work, GAF’s ScopeConnect<sup>SM</sup> service helps save time and money. { process.env.REACT_APP_MODE==="shareable" &&
              <a href="https://www.gaf.com/en-us/resources/business-services/scopeconnect?gad_source=1&gbraid=0AAAAAqijz3U_XhPUn4DNQC1WA0VWpIIR7&gclid=Cj0KCQiA0fu5BhDQARIsAMXUBOLQmMtzns62Jf5jwD9n11rKz7fWNsDF35cRDTw44NebOuXVzRB-UhgaAioLEALw_wcB&gclsrc=aw.ds" target="_blank" className="gaf-link">Learn More</a>
            }</p>
          <div className="hispanic-roofers__features">
            <div className="gaf-grid__cell insurance__feature-1">
              <img
                src={`img/insurance-feature-1.jpg`}
                alt="Insurance Feature 1"
              />
              <h3 className="gaf-grid__cell-title">Complete insurance SOW</h3>
              <p>Just answer a few questions</p>
            </div>
            <div className="gaf-grid__cell insurance__feature-2">
              <img
                src={`img/insurance-feature-2.jpg`}
                alt="Insurance Feature 2"
              />
              <h3 className="gaf-grid__cell-title">Fast turnaround</h3>
              <p>Estimate ready in less than 24 hours</p>
            </div>
            <div className="gaf-grid__cell insurance__feature-3">
              <img
                src={`img/insurance-feature-3.jpg`}
                alt="Insurance Feature 3"
              />
              <h3 className="gaf-grid__cell-title">Written by</h3>
              <p>Xactimate-certified and Symbility-trained experts</p>
            </div>
            <div className="gaf-grid__cell insurance__feature-4">
              <img
                src={`img/insurance-feature-4.jpg`}
                alt="Insurance Feature 4"
                className="cursor-pointer"
                onClick={() => setTestimonialsOpen(true)}
              />
              <h3 className="gaf-grid__cell-title">Testimonials</h3>
              <p>Insurance success stories</p>
              { process.env.REACT_APP_MODE==="shareable" &&
              <span href="#" onClick={() => setTestimonialsOpen(true)} className="gaf-link">Learn More</span>
              }
            </div>
          </div>
      </div>
    </div>
    <Testimonials
      color="blue"
      open={testimonialsOpen}
      setOpen={setTestimonialsOpen}
      testimonials={[
      {
        img: "one_world.jpg",
        quote: "Scope Connect has made insurance work simple. The tool works quickly and makes it easy even for new employees who are just getting into insurance. Before we had people help us out with this sort of thing but now we can do it all ourselves. Large or small jobs we now feel like we can do them all.",
        author_name: "Yordannys Hector",
        title: "One World Roofing",
        address: "Orlando, FL",
        fontSize: 30
      },
      {
        img: "gaf-logo.png",
        quote: "We had wanted to dip into insurance work for some time.  Scope Connect streamlined our confidence to enter this area of work.It has made it easy to order reports, look professional, and included more detail than what insurance companies provide. This is a tool that is reliable and I will continue to use it.",
        author_name: "Corey Battey",
        title: "Haywood Construction",
        address: "Del Rey, FL",
        fontSize: 30
      },
      ]}/>
  </>)
}
