import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import Nav from "../../components/Nav";
import Testimonials from "../../components/Testimonials";

const pfcSpreadsheet = "data_lowes_pfc.xlsx";

export default function PFC() {
  const [testimonialsOpen, setTestimonialsOpen] = useState(false);
  const [storeNumber, setStoreNumber] = useState(null);
  const [hasPFC, setHasPFC] = useState(false);
  const [hasRequestedCheck, setHasRequestedCheck] = useState(false);


  const getPFCData = async () => {
    try {
      fetch(pfcSpreadsheet)
        .then((response) => response.blob())
        .then((blob) => readXlsxFile(blob))
        .then((rows) => {
          const filteredRow = rows.filter((row) => {
            return row[0] === parseInt(storeNumber);
          });
          if (filteredRow.length > 0) {
            setHasPFC(true);
          }
          else {
            setHasPFC(false);
          }
          setHasRequestedCheck(true);
        });
    } catch (error) {
      setHasPFC(false);
      setHasRequestedCheck(true);
      console.error("Error fetching data: ", error);
    }
  };

  const checkStore = () => {
    getPFCData();
  }

  return (<>
    <Nav title={`Pro Fulfillment Center`} color="red" />
    <div className="hispanic-roofers pfc">
      <div className="hispanic-roofers__left">
        <img
          src={`img/pfc.jpg`}
          alt="PFC images"
        />
      </div>
      <div className="hispanic-roofers__right">
          <p className="pfc__text">Your store has access to next day delivery through your local PFC. Work with us to capitalize on this opportunity and grow your sales.</p>
          <div className="hispanic-roofers__features">
            <div className="gaf-grid__cell pfc__feature-1">
              <img
                src={`img/pfc-feature-1.jpg`}
                alt="Pfc Feature 1"
              />
              <h3 className="gaf-grid__cell-title">Next Day Delivery</h3>
              <p>Deliveries scheduled to meet your contractors expectations</p>
            </div>
            <div className="gaf-grid__cell pfc__feature-2">
              <img
                src={`img/pfc-feature-2.jpg`}
                alt="Pfc Feature 2"
              />
              <h3 className="gaf-grid__cell-title">Job Site Delivery</h3>
              <p>Products delivered where contractors want them</p>
            </div>
            <div className="gaf-grid__cell pfc__feature-3">
              <img
                src={`img/pfc-feature-3.jpg`}
                alt="Pfc Feature 3"
              />
              <h3 className="gaf-grid__cell-title">Job-Lot Quantities</h3>
              <p>Single transaction with everything a contractor needs</p>
            </div>
            <div className="gaf-grid__cell pfc__feature-4">
              <img
                src={`img/pfc-feature-4.jpg`}
                alt="Pfc Feature 4"
                className="cursor-pointer"
                onClick={() => setTestimonialsOpen(true)}
              />
              <h3 className="gaf-grid__cell-title">Testimonials</h3>
              <p>PFC success stories</p>
              { process.env.REACT_APP_MODE==="shareable" &&
              <span href="#" onClick={() => setTestimonialsOpen(true)} className="gaf-link">Learn More</span>
              }
            </div>
          </div>
          <div className={`pfc__form ${hasRequestedCheck ? 'active' : ''}`}>
            <div className={`pfc__form-row`}>
              <input onChange={(e) => { setHasRequestedCheck(false); setStoreNumber(e.target.value); }} value={storeNumber} type="text" placeholder="Enter Store Number" />
              <button onClick={checkStore} className="btn btn--red">Enter</button>
            </div>
            {hasRequestedCheck && <p>{hasPFC ? `Store #${storeNumber} is supported by a Pro Fulfillment Center` : `Store #${storeNumber} is not supported by a Pro Fulfillment Center`}</p>}
          </div>
      </div>
    </div>
    <Testimonials
      color="red"
      open={testimonialsOpen}
      setOpen={setTestimonialsOpen}
      testimonials={[{
        img: "Andrew Adorno.png",
        quote: "I have been able to service roofing customers at a high level with Jay Sayers. The partnership that we have developed allows us to leverage the FFC which has been a game changing tool for our pros.",
        author_name: "Andrew Adorno",
        title: "Pro Sales Manager",
        address: "Kissimmee, FL",
        fontSize: 36
      },
      {
        img: "Lisa Triplett.png",
        quote: "Brendon has been a critical partner in my business since the very beginning of my work as a Trades PSM.  Brendon has helped me with everything from answering warranty and technical questions for my customers, to being the carrot I dangle to get a second meeting with a roofer.",
        author_name: "Lisa Triplett",
        title: "Pro Sales Manager",
        address: "Atlanta, GA",
        fontSize: 30
      },
      {
        img: "John Hevesky.jpg",
        quote: "My sales have increased tremendously after putting Brendon in front of my customers. His presentation, mannerisms, knowledge and dedication to his product is second to none.",
        author_name: "John Hevesy",
        title: "Pro Sale Manager",
        address: "Atlanta, GA",
        fontSize: 38
      },
      {
        img: "Hard Core Roofing.jpeg",
        quote: "The support from Brendon, PSM, and the prodesk has excelled my business. They allow me to install all of my GAF roofs in a timely fashion even delivering next day through the PFC. They go above and beyond to take care of my needs. They have driven additional sales with certification.",
        author_name: "Ryan Haley",
        title: "Hard-Core Roofing",
        address: "Talking Rock, GA",
        fontSize: 30
      }
      ]}/>
  </>)
}
