import React from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Nav({ children, className, title = "2024 Attachment Rate Sales Data (Jan 1,2024 - Dec 31, 2024)", color = "green" }) {
  const [cookies, setCookie] = useCookies(['dataSource', 'locationId']);

  ///location/:dataSource/:locationId/:pageState

  return (
    <nav
      role="navigation"
      className={`py-2 flex flex-col justify-center items-center w-full navigation navigation--${color} ${className}`}
    >
      <div id="menuToggle">
        <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu">
          { process.env.REACT_APP_MODE!=="shareable" && <>
          <Link className="navigation__link--green" to="/kiosk3"><li>Attachment Rate</li></Link>
          </>}
          { process.env.REACT_APP_MODE==="shareable" && <>
          <Link className="navigation__link--green" to={`/location/${cookies.dataSource}/${cookies.locationId}/1`}><li>Attachment Rate</li></Link>
          </>}
          <Link className="navigation__link--yellow" to="/hispanic-roofers"><li>Hispanic Roofers</li></Link>
          <Link className="navigation__link--blue" to="/insurance"><li>Insurance</li></Link>
          <Link className="navigation__link--red" to="/pfc"><li>PFC</li></Link>
          <Link to="/sample-reports"><li>Sample Reports</li></Link>
          { process.env.REACT_APP_MODE!=="shareable" && <>
            <Link to="/rsr-contact"><li>RSR Contact</li></Link>
          </>}
        </ul>
      </div>
      <h1>{title}</h1>
    </nav>
  );
}
