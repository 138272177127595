import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Nav from "../../components/Nav";

// const { ipcRenderer } = window.require("electron");

function Kiosk3() {
  const navigate = useNavigate();
  const [storeNumber, setStoreNumber] = useState(0);
  const [dataSource, setDataSource] = useState("store");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      setStoreNumber(event.target.value);
      navigate(`/location/${dataSource}/${storeNumber}/1`);
    }
  };

  const handleInput = (event) => {
    setStoreNumber(event.target.value);
    setDataSource(event.target.attributes.source.value);
  };

  useEffect(() => {
    // If Store Field is not active, reload page to replay animations
    setTimeout(function () {
      if (storeNumber > 0 || document.activeElement.id === "StoreInput") {
        //do nothing
        console.log("store is " + storeNumber);
      } else {
        console.log("reloading page");
        // window.location.reload(1);
      }
    }, 10000);
  }, []);


  return (<>
    <Nav title="2024 Attachment Rate Sales Data (Jan 1,2024 - Dec 31, 2024)" />
    <div className="App bg-white flex flex-col gap-5 h-screen justify-center items-center relative p-40 w-full">
      <div className="input-logos flex gap-5 animate glow delay-1 mb-[45px]">
        <img src="svg/gaf_logo.svg" alt="gaf logo" />
        <img src="svg/lowes_logo.svg" alt="gaf logo" width={100} />
      </div>
      <div className="flex flex-col justify-center items-center bg-gray-200 p-16 gap-8 animate glow delay-2 w-full">
        <h1 className="text-3xl text-center font-bold text-black w-full animate fade delay-3 home-title">
          What Is Your Roofing <br/>
          Accessory Attachment Rate?
        </h1>
        <div className="input-container flex">
          <div className="flex flex-col grow">
            <p className="text-base animate fade delay-4 text-left">
              STORE NUMBER
            </p>
            <input
              className="placeholder-gray-300 py-3 px-6 border border-gray-400 outline-none animate fade delay-4 text-lg bg-stone-50"
              placeholder="Enter Store Number"
              onChange={handleInput}
              onKeyDown={handleKeyDown}
              id="StoreInput"
              source="store"
            />
          </div>
          <div className="text-lg">Or</div>
          <div className="flex flex-col grow">
            <p className="text-base animate fade delay-4 text-left">DISTRICT</p>
            <input
              className="placeholder-gray-300 py-3 px-6 border border-gray-400 outline-none animate fade delay-4 text-lg bg-stone-50"
              placeholder="Select A District"
              onChange={handleInput}
              onKeyDown={handleKeyDown}
              id="DistrictInput"
              source="district"
            />
          </div>
        </div>
      </div>

      <div className="input-btns flex gap-4 w-full">
        {/* Int parameter on the end of the url is passed to setPage state in the store view */}
        <Link
          className="py-4 px-9 bg-[#D71920] text-2xl text-white font-bold cursor-pointer w-full text-center animate glow delay-5 uppercase"
          to={`/location/${dataSource}/${storeNumber}/1`}
        >
          View Full Sales Data Chart
        </Link>

        <Link
          className="py-4 px-9 bg-[#D71920] text-2xl text-white font-bold cursor-pointer w-full text-center animate glow delay-5 uppercase"
          to={`/location/${dataSource}/${storeNumber}/3`}
        >
          View Roof Model With Sales Chart
        </Link>
      </div>

      <Link className="absolute left-0 bottom-0 w-14 h-14" to="/" />
    </div>
    </>
  );
}

export default Kiosk3;
