import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RoofButton from "../../components/RoofButton";
import GAFCard from "../../components/GAFCard";
import RoofCard from "../../components/RoofCard";

export default function Three({
  dataSource,
  storeDatas,
  locationId,
  storeData,
  barrier,
  protection,
  starter,
  shingles,
  attic,
  originalLocationId,
  setTestimonialsOpen
}) {
  const [detailView, setDetailView] = useState(false);
  const [detailType, setDetailType] = useState();
  const [videoSrc, setVideoSrc] = useState("");
  const [playbackTime, setPlaybackTime] = useState(0);

  //Play videos until the playback time
  useEffect(() => {
    const video = document.querySelector("#video");
    video.currentTime = 0;
    video.play();
    const timeoutId = setTimeout(() => {
      video.pause();
    }, playbackTime * 1000);

    // Cleanup function to clear the timeout
    return () => clearTimeout(timeoutId);
  }, [videoSrc, playbackTime]);

  //Re-play videos at the playback time when the detail view is closed
  useEffect(() => {
    if (detailView === false) {
      const video = document.querySelector("#video");
      video.currentTime = playbackTime;
      video.play();
    }
  }, [detailView]);

  //Update the video source and playback time when a roof button is clicked
  const handleRoofButtonClick = (type, src, time) => {
    setDetailView(true);
    setDetailType(type);
    setVideoSrc(src);
    setPlaybackTime(time);

    const video = document.querySelector("#video");
    video.src = src;
  };

  return (
    <div className="flex items-center bg-black h-screen">
      <div className="roof-view flex flex-col overflow-hidden w-full relative h-full">
        <video id="video" muted className="w-full h-full object-cover absolute">
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <span className="roof-view-gradient"></span>

        {!detailView && (
          <div className="roof-view-landing animate fade delay-2 h-full p-[35px] relative w-full">
            <h2 className="roof-view-header text-white text-3xl mx-auto my-0 max-w-[1000px] text-center">
              Select a component of the GAF Roofing System to learn more about
              your attachment rates.
            </h2>
            <RoofButton
              id="1"
              title="LEAK BARRIER"
              {...barrier}
              onClick={() => {
                handleRoofButtonClick("barrier", "videos/barrier.mp4", 23);
              }}
              dataSource={dataSource}
            />
            <RoofButton
              id="2"
              title="DECK PROTECTION"
              {...protection}
              onClick={() => {
                handleRoofButtonClick(
                  "protection",
                  "videos/protection.mp4",
                  13
                );
              }}
              dataSource={dataSource}
            />
            <RoofButton
              id="3"
              title="STARTER STRIP SHINGLES"
              {...starter}
              onClick={() => {
                handleRoofButtonClick("starter", "videos/starter.mp4", 16);
              }}
              dataSource={dataSource}
            />
            <div
              id="shingles-sales"
              className={`roof-button flex items-center justify-between gap-5 absolute`}
              title="SHINGLES SALES"
            >
              <figure
                className={`roof-button-icon flex align-center justify-center rounded-full bg-stone-50 border-solid border-2 border-stone-50`}
              >
                <img src={`svg/4.svg`} width={50} alt="4" />
              </figure>
              <div>
                <h4 className="text-lg text-stone-50 uppercase text-center">
                  Shingles Sales
                </h4>
                <p
                  className={`roof-button-flag rounded py-2 pt-4 px-5 text-3xl font-semibold bg-stone-50`}
                >
                  ${storeData[21].toLocaleString()}
                </p>
              </div>
            </div>
            <RoofButton
              id="5"
              title="ATTIC VENTILATION"
              {...attic}
              onClick={() => {
                handleRoofButtonClick("attic", "videos/attic.mp4", 14);
              }}
              dataSource={dataSource}
            />
            <RoofButton
              id="6"
              title="HIP & RIDGE CAP SHINGLES"
              {...shingles}
              onClick={() => {
                handleRoofButtonClick("shingles", "videos/shingles.mp4", 22);
              }}
              dataSource={dataSource}
            />
          </div>
        )}

        {detailView && (
          <>
            {detailType === "barrier" && (
              <RoofCard setDetailView={setDetailView}>
                <GAFCard
                  title="LEAK BARRIER"
                  {...barrier}
                  id="1"
                  detailView={detailView}
                  dataSource={dataSource}
                  storeDatas={storeDatas}
                />
              </RoofCard>
            )}
            {detailType === "protection" && (
              <RoofCard setDetailView={setDetailView}>
                <GAFCard
                  title="DECK PROTECTION"
                  {...protection}
                  id="2"
                  detailView={detailView}
                  dataSource={dataSource}
                  storeDatas={storeDatas}
                />
              </RoofCard>
            )}
            {detailType === "starter" && (
              <RoofCard setDetailView={setDetailView}>
                <GAFCard
                  title="STARTER STRIP SHINGLES"
                  {...starter}
                  id="3"
                  detailView={detailView}
                  dataSource={dataSource}
                  storeDatas={storeDatas}
                />
              </RoofCard>
            )}
            {detailType === "attic" && (
              <RoofCard setDetailView={setDetailView}>
                <GAFCard
                  title="ATTIC VENTILATION"
                  {...attic}
                  id="5"
                  detailView={detailView}
                  dataSource={dataSource}
                  storeDatas={storeDatas}
                />
              </RoofCard>
            )}
            {detailType === "shingles" && (
              <RoofCard setDetailView={setDetailView}>
                <GAFCard
                  title="HIP & RIDGE CAP SHINGLES"
                  {...shingles}
                  id="6"
                  detailView={detailView}
                  dataSource={dataSource}
                  storeDatas={storeDatas}
                />
              </RoofCard>
            )}
          </>
        )}

        <div className="roof-view-footer flex gap-4 items-center justify-center absolute bottom-10 w-full px-16 animate fade delay-2">
          { process.env.REACT_APP_MODE!="shareable" &&
          <Link
            to={`/kiosk3`}
            className="roof-view-store-id text-base font-semibold flex items-center justify-center  text-[#D71920] bg-stone-50/[.85] rounded-full capitalize text-center"
          >
            {dataSource} ID
          </Link>
          }
          <div className="roof-view-rates flex items-center justify-between  bg-stone-50/[.85] p-4 pl-10 rounded-full">
            <h3 className="text-base uppercase">
              Your {dataSource}'s RESIDENTIAL ACCESSORY ATTACHMENT RATE:
            </h3>
            <p className="text-3xl text-[#D71920] font-semibold text-center">
              {(storeData[8] * 100).toFixed(2)}%
            </p>
            <Link
              to={`/location/${dataSource}/${originalLocationId}/1`}
              className="roof-view-connect text-stone-50 bg-[#D71920] py-4 px-5 text-lg rounded-full text-center uppercase"
            >
              View All Attachment Rates
            </Link>
          </div>
          { process.env.REACT_APP_MODE!="shareable" &&
          <>
            <Link
              to={`/partner/${dataSource}/${locationId}`}
              className="roof-view-connect text-[#D71920] bg-stone-50 py-4 px-5 text-lg rounded-full uppercase min-w-[290px] text-center"
            >
              Connect Me With An RSR
            </Link>
            <a onClick={() => setTestimonialsOpen(true)} className="cursor-pointer roof-view-connect text-[#D71920] bg-stone-50 py-4 px-5 text-lg rounded-full uppercase min-w-[290px] text-center">Testimonials</a>
          </>
          }
        </div>
      </div>
    </div>
  );
}
