import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import Nav from "../../components/Nav";

const numPages = {
  "quickmeasure": 11,
  "multifamily-quickmeasure": 11,
  "scopeconnect": 7,
  "takeoff": 9
}

const sampleReports = {
  "quickmeasure": {
    title: "Single-family QuickMeasure",
  },
  "multifamily-quickmeasure": {
    title: "Multi-family QuickMeasure",
  },
  "scopeconnect": {
    title: "ScopeConnect",
  },
  "takeoff": {
    title: "Takeoff",
  }
}

export default function SampleReportsViewer() {
  let { report } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const pages = [];
  for(let i = 1; i <= numPages[report]; i++) {
    pages.push(String(i).padStart(2, '0'));
  }
  return (<>
    <Nav title={`Sample Reports`} color="white" />
    <div className="sample-reports-viewer">
      {
        pages.map((page) => <img src={`sample-reports/${report}/Page${page}.jpg`} />)
      }
    </div>
    <div className="sample-reports-viewer__tools">
      {Object.keys(sampleReports).map((sampleReport) => <Link to={`/sample-reports/${sampleReport}#`} className="sample-reports-viewer__link roof-view-connect py-2 px-5 text-lg rounded-full uppercase border-[#D71920] border-solid border-2 text-center min-w-[150px] uppercase bg-[#D71920] text-white">{sampleReports[sampleReport].title}</Link>)}
    </div>
  </>)
}
