 import React from "react";
import ReactDOM from "react-dom/client";
import "./index.min.css";
import App from "./pages/App";
import Location from "./pages/Location";
import { createHashRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Partner from "./pages/Partner";
import Error from "./pages/Error";
import Kiosk1 from "./pages/Kiosk1";
import Kiosk2 from "./pages/Kiosk2";
import Kiosk3 from "./pages/Kiosk3";
import HispanicRoofers from "./pages/HispanicRoofers";
import HispanicRoofersTwo from "./pages/HispanicRoofers/Two";
import Insurance from "./pages/Insurance";
import PFC from "./pages/PFC";
import SampleReports from "./pages/SampleReports";
import SampleReportsViewer from "./pages/SampleReports/Viewer";
import { CookiesProvider } from 'react-cookie';

let router = createHashRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
  },
  {
    path: "/location/:dataSource/:locationId/:pageState",
    element: <Location />,
  },
  {
    path: "/partner/:dataSource/:locationId",
    element: <Partner />,
  },
  {
    path: "/kiosk1",
    element: <Kiosk1 />,
  },
  {
    path: "/kiosk2",
    element: <Kiosk2 />,
  },
  {
    path: "/kiosk3",
    element: <Kiosk3 />,
  },
  {
    path: "/hispanic-roofers",
    element: <HispanicRoofers />,
  },
  {
    path: "/insurance",
    element: <Insurance />,
  },
  {
    path: "/pfc",
    element: <PFC />,
  },
  {
    path: "/sample-reports",
    element: <SampleReports />,
  },
  {
    path: "/sample-reports/:report",
    element: <SampleReportsViewer />,
  },
  {
    path: "/rsr-contact",
    element: <Partner />,
  },
]);

if(process.env.REACT_APP_MODE === "shareable") {
  router = createHashRouter([
    {
      path: "/location/:dataSource/:locationId/:pageState",
      element: <Location />,
      errorElement: <Error />,
    },
    {
      path: "/hispanic-roofers",
      element: <HispanicRoofers />,
    },
    {
      path: "/insurance",
      element: <Insurance />,
    },
    {
      path: "/pfc",
      element: <PFC />,
    },
    {
      path: "/sample-reports",
      element: <SampleReports />,
    },
    {
      path: "/sample-reports/:report",
      element: <SampleReportsViewer />,
    },
    {
      path: "*",
      element: <Location />,
      errorElement: <Error />,
    }
  ]);
}

const toggleFullscreen = () => {
  if (!document.fullscreenElement &&    // alternative standard method
    !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <RouterProvider router={router} />
      <button className="w-14 h-14 bottom-0 absolute" style={{left: '3.5rem'}} onClick={toggleFullscreen}></button>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
