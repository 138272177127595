import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import Nav from "../../components/Nav";
import Testimonials from "../../components/Testimonials";

export default function HispanicRoofers() {
  const [testimonialsOpen, setTestimonialsOpen] = useState(false);

  return (<>
    <Nav title={`Hispanic Roofers`} color="yellow" />
    <div className="hispanic-roofers">
      <div className="hispanic-roofers__left">
        <img
          src={`img/hispanic-roofers.jpg`}
          alt="Hispanic Roofer images"
        />
      </div>
      <div className="hispanic-roofers__right">
          <p>GAF is committed to providing Hispanic roofing contractors with educational resources on products and warranties, as well as training in marketing and in-home sales.
            { process.env.REACT_APP_MODE==="shareable" &&
              <a href="https://www.gaf.com/en-us/for-pros/care-training/latinos-in-roofing" target="_blank" className="gaf-link">Learn More</a>
            }
          </p>
          <div className="hispanic-roofers__features">
            <div className="gaf-grid__cell hispanic-roofers__feature-1">
              <img
                src={`img/hispanic-roofers-feature-1.png`}
                alt="Hispanic Roofer Feature 1"
              />
              <h3 className="gaf-grid__cell-title">Business Consultation By GAF</h3>
            </div>
            <div className="gaf-grid__cell hispanic-roofers__feature-2">
              <img
                src={`img/hispanic-roofers-feature-2.jpg`}
                alt="Hispanic Roofer Feature 2"
              />
              <h3 className="gaf-grid__cell-title">Marketing Materials</h3>
              <p>Marketing Materials aimed at growing Hispanic Roofers Business’s</p>
            </div>
            <div className="gaf-grid__cell hispanic-roofers__feature-3">
              <img
                src={`img/hispanic-roofers-feature-3.jpg`}
                alt="Hispanic Roofer Feature 3"
              />
              <p>Community Based initiative to train and educate Hispanic Roofers</p>
              { process.env.REACT_APP_MODE==="shareable" &&
              <a href="https://www.gaf.com/en-us/for-pros/care-training/latinos-in-roofing" target="_blank" className="gaf-link">Learn More</a>
              }
            </div>
            <div className="gaf-grid__cell hispanic-roofers__feature-4">
              <img
                src={`img/hispanic-roofers-feature-4.jpg`}
                alt="Hispanic Roofer Feature 4"
                className="cursor-pointer"
                onClick={() => setTestimonialsOpen(true)}
              />
              <h3 className="gaf-grid__cell-title">Testimonials</h3>
              <p>Hispanic Roofers Success Stories</p>
              { process.env.REACT_APP_MODE==="shareable" &&
              <span href="#" onClick={() => setTestimonialsOpen(true)} className="gaf-link">Learn More</span>
              }
            </div>
          </div>
      </div>
    </div>
    <Testimonials
      color="yellow"
      open={testimonialsOpen}
      setOpen={setTestimonialsOpen}
      testimonials={[{
        img: "hispanic_testimonial_1.jpg",
        quote: "Derrick and Lowe’s has helped me excel my business in the roofing space. Not only does GAF offer great products and marketing materials, but outstanding warranties for my customers.",
        author_name: "Eli Terrazas",
        title: "Gorilla Roofing",
        address: "San Antonio, TX",
        fontSize: 36
      },
      {
        img: "hispanic_testimonial_2.jpg",
        quote: "Our work as a whole, in conjunction with, and assistance from the Lowe’s Pro Desk team including Mercedes, Angie, Claire and Indira as well as the GAF representative Mario Luna has been great. Their assistance, general help and knowledge have been invaluable in moving forward as a business.",
        author_name: "Raymond Roman",
        title: "The Granite Shop",
        address: "Jackson, TX",
        fontSize: 30
      }
      ]}/>
  </>)
}
