import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Testimonials({ children, testimonials, open = false, setOpen = () => {}, color = "red" }) {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    prevArrow: <button className="testimonials__prev"><svg xmlns="http://www.w3.org/2000/svg" width="18.072" height="39.288" viewBox="0 0 18.072 39.288">
    <path id="Up_Arrow" data-name="Up Arrow" d="M16,38,0,19,16,0" transform="translate(1.307 0.644)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
  </svg>
  Previous
  </button>,
    nextArrow: <button className="testimonials__next"><svg xmlns="http://www.w3.org/2000/svg" width="18.072" height="39.288" viewBox="0 0 18.072 39.288">
    <path id="Up_Arrow" data-name="Up Arrow" d="M0,38,16,19,0,0" transform="translate(0.765 0.644)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
  </svg>
  Next
  </button>,
  };
  return (
    <div className={`testimonials ${open ? 'active' : ''} testimonials--${color}`}>
      <div className="testimonials__container">
        <div className="testimonials__header">
          <button className="testimonials__close" onClick={() => setOpen(false)}>
            <span></span>
            <span></span>
          </button>

          <h2>Testimonials</h2>
        </div>
        <div className="testimonials__slider">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div className="testimonials__slide">
                <div className="testimonials__quote-container">
                  <img
                    src={`testimonials/${testimonial.img}`}
                    alt="testimonial"
                  />
                  <div className="testimonials__quote" style={{ fontSize: testimonial.fontSize ?? 54 }}>"{testimonial.quote}"</div>
                </div>
                <div className="testimonials__author">
                  <p className="testimonials__author-name">
                    {testimonial.author_name}
                  </p>
                  <p className="testimonials__author-title">
                    {testimonial.title}
                  </p>
                  <p className="testimonials__author-address">
                    {testimonial.address}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
